<template>
    <div id="mc-404" class="container-fluid d-flex justify-content-center align-items-center">
        <div>
            <span class="mc--title text-warning"> 404 </span> <br>
            <span class="mc--title"> Page Not Found </span>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'mc-404',
        data() {
            return {

            }
        }
    }
</script>

<style scoped lang="scss">
    #mc-404 {
        background-color: #aaa;
        min-height: 800px;
        padding: 100px;
    }
</style>
